function PPTViewer(props: any) {
	if (props.fileName.includes("Product and Compliance")) {
		return (
			<>
				<iframe
					src="https://docs.google.com/presentation/d/e/2PACX-1vQLddNCiKn7pBcdBu9D5v57O--xOcs5HzIkaXdDtPdQ21wj-Mfo2YYHSmNJcdhTOA/embed?start=false&loop=true&delayms=3000"
					allowFullScreen
					frameBorder="0"
					width="95%"
					height="100%"
				></iframe>
			</>
		);
	} else if (props.fileName.includes("SAP")) {
		return (
			<>
				<iframe
					src="https://docs.google.com/presentation/d/e/2PACX-1vTI65qcdw2a571-z1xji3ClCjCDd86So-PWDmlgKGNXjae_ZC2UflJmpYMFSPPo5w/embed?start=false&loop=true&delayms=3000"
					allowFullScreen
					frameBorder="0"
					width="95%"
					height="100%"
				></iframe>
			</>
		);
	} else if (props.fileName.includes("Build Array")) {
		return (
			<>
				<iframe
					src="https://docs.google.com/presentation/d/e/2PACX-1vS7Xb35x_sS9jdCmRFw_QVEppSFlov9EykO9EaD-yaGj3C71gSL1LjhZThYbb2y1Q/embed?start=false&loop=true&delayms=3000"
					allowFullScreen
					frameBorder="0"
					width="95%"
					height="100%"
				></iframe>
			</>
		);
	} else
		return (
			<iframe
				src={`../TrainingDocs/${props.fileName}`}
				width="90%"
				height="80%"
				frameBorder="0"
				title="slides"
			></iframe>
		);
}

export default PPTViewer;
