import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#0071ce',
        },
        secondary: {
            main: '#ffc221',
            contrastText: '#fff'
        }
    },
});

export default theme;