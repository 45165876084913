import axios from "axios";
import { API_URL } from '../config';

class FileService {
    getFilesList = (token: string | null) => {
        return (axios.get(`${API_URL}/files`, {
            headers: {
                Authorization: `Bearer: ${token}`
            }
        }));
    }

    getFile = (fileName: string, token: string | null) => {
        return (axios.get(`${API_URL}/file/${fileName}`, {
            headers: {
                Authorization: `Bearer: ${token}`
            }
        }));
    }
}

export default FileService;