import CloseIcon from "@mui/icons-material/Close";
import { Box, Modal, Typography, Button, IconButton } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import PPTViewer from "./PPTViewer";

function InfoModal(props: any) {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: props.isFileViewer ? '80vw' : 400,
        height: props.isFileViewer ? "80vh" : "auto",
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '3px',
        p: 4,
        textAlign: "center"
    };
    const pass = {
        color: '#4caf50'
    }
    const fail = {
        color: "#f44336"
    }

    return (
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <IconButton aria-label="close" onClick={props.handleClose} sx={{ position: "absolute", top: "10px", right: "10px" }}>
                    <CloseIcon />
                </IconButton>
                {
                    props.modalContent &&
                    <>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {props.modalContent}
                        </Typography>
                        <br />
                    </>
                }
                {
                    props.pass ? props.subContent && props.isResult && <Typography id="modal-subcontent" variant="h5">
                        <b style={pass}>{props.subContent}</b>
                    </Typography>
                        :
                        props.subContent && props.isResult && <Typography id="modal-subcontent" variant="h5">
                            <b style={fail}>{props.subContent}</b>
                        </Typography>
                }
                {
                    props.subContent && !props.isResult && !props.isFileViewer && <Typography id="modal-subcontent" component="p">
                        <b>{props.subContent}</b>
                    </Typography>
                }
                {
                    props.isFileViewer && <PPTViewer fileName={props.fileName} />
                }
                <br />
                <div>
                    {
                        props.buttonText[1] && <> <LoadingButton loading={props.loading} variant="contained" color="secondary" onClick={props.yesClick}>{props.buttonText[0]}</LoadingButton>
                            &nbsp;&nbsp;&nbsp;<Button variant="outlined" onClick={props.handleClose} color="error">{props.buttonText[1]}</Button></>
                    }
                    {
                        !props.buttonText[1] && !props.isFileViewer && <Button variant="contained" onClick={props.handleClose} color="secondary">{props.buttonText[0]}</Button>
                    }
                </div>
            </Box>
        </Modal>
    )
}

export default InfoModal;