import { useEffect, useState } from "react";
import {
	Button,
	CircularProgress,
	FormControl,
	Typography,
} from "@mui/material";
import DatabaseService from "../Services/DatabaseService";
import Question from "./Question";
import InfoModal from "./Modal";
import EmailService from "../Services/EmailService";

function Assessment(props: any) {
	let finalMark: number = 0;
	const [questions, setQuestions] = useState([]);
	const [selectedAnswers, setSelectedAnswers] = useState<Array<any>>([]);
	const [open, setOpen] = useState(false);
	const [errorOpen, setErrorOpen] = useState(false);
	const [cancelOpen, setCancelOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [loading, setLoading] = useState(true);
	const handleClose = () => setOpen(false);
	const handleErrorOpen = () => setErrorOpen(true);
	const handleErrorClose = () => setErrorOpen(false);
	const handleCancelOpen = () => setCancelOpen(true);
	const handleCancelClose = () => setCancelOpen(false);
	const databaseService = new DatabaseService();
	const emailService = new EmailService();
	const correctAnswers: any[] = new Array(questions.length);
	const [submitDisabled, setSubmitDisabled] = useState(false);
	const [modalLoading, setModalLoading] = useState(false);

	const handleCancel = () => {
		handleCancelOpen();
	};

	const handleOpen = () => {
		setErrorMessage("");
		let errors = 0;

		for (const { i, selectedAnswer } of selectedAnswers.map(
			(selectedAnswer, i) => ({ selectedAnswer, i })
		)) {
			if (selectedAnswer === "") {
				errors++;
				setErrorMessage(
					`Please choose an answer for Question ${i + 1}`
				);
				handleErrorOpen();
				break;
			}
		}
		if (errors === 0) setOpen(true);
	};

	function shuffle(array: any) {
		let currentIndex = array.length,
			randomIndex;

		// While there remain elements to shuffle...
		while (currentIndex !== 0) {
			// Pick a remaining element...
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex--;

			// And swap it with the current element.
			[array[currentIndex], array[randomIndex]] = [
				array[randomIndex],
				array[currentIndex],
			];
		}

		return array;
	}

	const cancelAssessment = () => {
		props.setShowAssessment(false);
	};

	const hideAssessment = () => {
		setModalLoading(true);
		setSubmitDisabled(true);
				
		for (const { i, selectedAnswer } of selectedAnswers.map(
			(selectedAnswer, i) => ({ selectedAnswer, i })
		)) {
			const localQuestions: any[] = questions;
			const answers: any[] = localQuestions[i].answers;

			// eslint-disable-next-line no-loop-func
			answers.forEach((answer: any[]) => {
				if (selectedAnswer === answer[0]) {
					if (answer[1] === true) {
						correctAnswers[i] = {
							correct: true,
							question: localQuestions[i].question,
							answer: answer[0],
						};
						finalMark += 100 / questions.length;
					} else {
						correctAnswers[i] = {
							correct: false,
							question: localQuestions[i].question,
							answer: answer[0],
						};
					}
				}
			});
		}

		if (props.assessmentName === 'Extended Warranty Compliance' && finalMark >= 90) {
			let ewPassed = true;
			props.user.ewPassed = ewPassed;
		}

		databaseService
			.updateUser(
				{
					name: "results",
					user: props.user,
					object: {
						assessmentName: props.assessmentName,
						assessmentDate: new Date(),
						finalMark: finalMark,
						selectedAnswers: correctAnswers,
					},
				},
				localStorage.getItem("token")
			)
			.then((res) => {
				let userInfo = props.user;

				if (userInfo.results) {
					userInfo.results.push({
						assessmentName: props.assessmentName,
						assessmentDate: new Date(),
						finalMark: finalMark,
						selectedAnswers: correctAnswers,
					});
					props.setUser({
						...userInfo,
						results: userInfo.results,
					});
					localStorage.setItem(
						"user",
						JSON.stringify({
							...userInfo,
							results: userInfo.results,
						})
					);
				} else {
					props.setUser({
						...userInfo,
						results: [
							{
								assessmentName: props.assessmentName,
								assessmentDate: new Date(),
								finalMark: finalMark,
								selectedAnswers: correctAnswers,
							},
						],
					});
					localStorage.setItem(
						"user",
						JSON.stringify({
							...userInfo,
							results: [
								{
									assessmentName: props.assessmentName,
									assessmentDate: new Date(),
									finalMark: finalMark,
									selectedAnswers: correctAnswers,
								},
							],
						})
					);
				}

				databaseService
					.getEmailUsers(
						props.assessmentName,
						localStorage.getItem("token")
					)
					.then((emailRes: any) => {
						let toEmails: string[] = [];
						emailRes.data.message.forEach(
							(user: { emailAddress: string }) => {
								toEmails.push(user.emailAddress);
							}
						);
						emailService
							.sendMail(
								{
									results: {
										finalMark: finalMark,
										assessmentName: props.assessmentName,
										passMark: props.passMark
									},
									user: {
										name: `${userInfo.firstName} ${userInfo.lastName}`,
									},
									emailUsers: toEmails,
								},
								localStorage.getItem("token")
							)
							.then(() => {})
							.catch(() => {
								console.error("Error sending email");
							});
						props.setShowAssessment(false);
						props.setResult(finalMark);
						props.setResultModalOpen(true);
					})
					.catch((err: any) => {
						console.error("Error sending email");
					});
			})
			.catch((err) => {
				console.error(err);
			});
	};

	useEffect(() => {
		const questions = props.assessment.questions;

		setLoading(false);
		setQuestions(shuffle(questions));
		setSelectedAnswers([
			...Array(questions.length)
				.fill(null)
				.map(() => ""),
		]);
	}, []);

	return (
		<div>
			<FormControl>
				<Typography variant="h4" textAlign={"center"}>
					{props.assessmentName} Assessment Questions
				</Typography>
				{loading && (
					<div style={{ textAlign: "center", padding: "2em" }}>
						<CircularProgress color="secondary" />
					</div>
				)}
				<br />
				{questions.map((question: any, i: number) => {
					return (
						<Question
							question={question.question}
							answers={question.answers}
							number={(i + 1).toString()}
							setSelectedAnswers={setSelectedAnswers}
							selectedAnswers={selectedAnswers}
							shuffle={shuffle}
							key={i}
						/>
					);
				})}
				<div>
					<Button
						variant="contained"
						color="secondary"
						type="submit"
						onClick={handleOpen}
					>
						Submit
					</Button>{" "}
					&nbsp;&nbsp;&nbsp;
					<Button
						variant="outlined"
						color="error"
						onClick={handleCancel}
					>
						Cancel
					</Button>
				</div>
			</FormControl>
			<InfoModal
				open={open}
				handleClose={handleClose}
				modalContent="Are you sure you would like to submit your answers?"
				subContent={`I acknowledge that I completed the ${props.assessmentName} assessment on my own. I understand and agree to abide by the requirements, policies and procedures as set out in the ${props.assessmentName} training.`}
				yesClick={hideAssessment}
				buttonText={["Yes", "No"]}
				disabled={submitDisabled}
				loading={modalLoading}
			/>
			<InfoModal
				open={errorOpen}
				handleClose={handleErrorClose}
				modalContent={errorMessage}
				buttonText={["Okay"]}
			/>
			<InfoModal
				open={cancelOpen}
				handleClose={handleCancelClose}
				modalContent="Are you sure you would like to cancel your assessment?"
				yesClick={cancelAssessment}
				buttonText={["Yes", "No"]}
			/>
		</div>
	);
}

export default Assessment;
