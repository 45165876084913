import {
	Box,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from "@mui/material";
import { useState, useEffect } from "react";
import "./Home.css";
import Login from "../Components/Login";
import { styled, ThemeProvider } from "@mui/material/styles";
import FileService from "../Services/FileService";
import DatabaseService from "../Services/DatabaseService";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Assessment from "../Components/Assessment";
import ResultsTable from "../Components/ResultsTable";
import InfoModal from "../Components/Modal";
import theme from "../Theme";
import Logout from "@mui/icons-material/Logout";

type User = {
	firstName: String;
	lastName: String;
	phoneNumber: Number;
	brokerNumber: Number;
	storeName: String;
	results: [
		{
			assessmentDate: Date;
			assessmentName: String;
			finalMark: Number;
			selectedAnswers: any[];
		}
	];
	ewPassed: boolean;
};

const DrawerHeader = styled("div")(({ theme }) => ({
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	})),
	AppBar = styled(MuiAppBar, {
		shouldForwardProp: (prop) => prop !== "open",
	})<AppBarProps>(({ theme }) => ({
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	})),
	pageName = "Extended Warranty Training";

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

function Home() {
	const fileService = new FileService(),
		databaseService = new DatabaseService(),
		[files, setFiles] = useState([]),
		columns: GridColumns = [
			{ field: "name", headerName: "Name", flex: 1 },
			{ field: "lastModified", headerName: "Date", width: 150 },
			{ field: "size", headerName: "Size", width: 100 },
		],
		[user, setUser] = useState<User | undefined>();

	const [open, setOpen] = useState(false);
	const [resultsOpen, setResultsOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const handleResultsClose = () => setResultsOpen(false);
	const [showAssessment, setShowAssessment] = useState(false);
	const [assessments, setAssessments] = useState([]);
	const [assessmentName, setAssessmentName] = useState("");
	const [assessmentPassMark, setAssessmentPassMark] = useState(0);
	const [result, setResult] = useState(0);
	const [resultModalOpen, setResultModalOpen] = useState(false);
	const [fileName, setFileName] = useState("");
	const [startAssessmentDisabled, setStartAssessmentDisabled] =
		useState(true);
	const [selectedAssessment, setSelectedAssessment] = useState({});

	const loadAssessment = () => {
		setOpen(false);
		setShowAssessment(true);
	};

	const handleShowResults = () => {
		setResultsOpen(true);
	};

	const handleAssessmentSelect = (event: SelectChangeEvent) => {
		setAssessmentName(event.target.value as string);
		setStartAssessmentDisabled(false);
		assessments.forEach((assessment: any) => {
			if (assessment.assessmentName === event.target.value) {
				setSelectedAssessment(assessment);
				setAssessmentPassMark(assessment.passMark);
			}
		});
	};

	const handleLogout = () => {
		localStorage.removeItem("user");
		setUser(undefined);
	};

	const updateUser = (fileName: string) => {
		databaseService.updateUser(
			{
				name: "fileViews",
				user: JSON.parse(localStorage.user),
				object: {
					fileName: fileName,
					viewDate: new Date(),
				},
			},
			localStorage.getItem("token")
		);
	};

	const handleResultModalClose = () => {
		setResultModalOpen(false);
	};

	const showFileViewerModal = (e: any) => {
		updateUser(e.value);
		setFileName(e.value);
	};

	const handleFileViewerClose = () => {
		setFileName("");
	};

	useEffect(() => {
		fileService
			.getFilesList(localStorage.getItem("token"))
			.then((res: any) => {
				if (user) {
					if (!user.ewPassed) {
						setFiles(
							res.data.message.filter(
								(e: { name: string }) =>
									e.name !==
									"SAP Access for Servi-Sure Agents - 08092022.pptx"
							)
						);
					} else {
						setFiles(res.data.message);
					}
				}
			})
			.catch((err: any) => {
				if (err.response.status === 403) {
					localStorage.removeItem("user");
					localStorage.removeItem("token");
					setUser(undefined);
				}
				console.error(err);
			});

		databaseService
			.getAssessments(localStorage.getItem("token"))
			.then((res: any) => {
				setAssessments(JSON.parse(atob(res.data.message)));
			})
			.catch((err: any) => {
				if (err) {
					console.error(err);
				}
			});
	}, [user]);

	if (user) {
		if (showAssessment) {
			return (
				<ThemeProvider theme={theme}>
					<Box
						sx={{
							display: "flex",
							paddingLeft: "20vw",
							paddingRight: "20vw",
						}}
					>
						<CssBaseline />
						<AppBar position="fixed">
							<Toolbar>
								<Typography
									variant="h6"
									noWrap
									component="div"
									sx={{ flexGrow: 1 }}
								>
									{pageName}
								</Typography>
								<Typography variant="h6" noWrap component="div">
									{`${
										JSON.parse(localStorage.user).firstName
									} ${
										JSON.parse(localStorage.user).lastName
									}`}
								</Typography>
								&nbsp;&nbsp;
								<IconButton
									aria-label="Logout"
									component="span"
									sx={{ color: "#fff" }}
									onClick={handleLogout}
								>
									<Logout />
								</IconButton>
							</Toolbar>
						</AppBar>
						<Box component="main" sx={{ flexGrow: 1, p: 3 }}>
							<DrawerHeader />
							<Assessment
								assessment={selectedAssessment}
								setShowAssessment={setShowAssessment}
								user={JSON.parse(localStorage.user)}
								setUser={setUser}
								setResult={setResult}
								setResultModalOpen={setResultModalOpen}
								assessmentName={assessmentName}
								passMark={assessmentPassMark}
							/>
						</Box>
					</Box>
				</ThemeProvider>
			);
		}
		return (
			<ThemeProvider theme={theme}>
				<Box
					sx={{
						display: "flex",
						paddingLeft: "20vw",
						paddingRight: "20vw",
					}}
				>
					<CssBaseline />
					<AppBar position="fixed">
						<Toolbar>
							<Typography
								variant="h6"
								noWrap
								component="div"
								sx={{ flexGrow: 1 }}
							>
								{pageName}
							</Typography>
							<Typography variant="h6" noWrap component="div">
								{`${JSON.parse(localStorage.user).firstName} ${
									JSON.parse(localStorage.user).lastName
								}`}
							</Typography>
							&nbsp;&nbsp;
							<IconButton
								aria-label="Logout"
								component="span"
								sx={{ color: "#fff" }}
								onClick={handleLogout}
							>
								<Logout />
							</IconButton>
						</Toolbar>
					</AppBar>
					<Box component="main" sx={{ flexGrow: 1, p: 3 }}>
						<DrawerHeader />
						<div>
							Please note that all agents must complete the&nbsp;
							<b>
								Extended Warranty (EW) Product, Compliance
								Training
							</b>
							.
						</div>
						<br />
						<div style={{ height: 400, width: "100%" }}>
							<DataGrid
								rows={files}
								columns={columns}
								pageSize={10}
								rowsPerPageOptions={[10]}
								onCellClick={showFileViewerModal}
							/>
						</div>
						<br />
						<FormControl fullWidth>
							<Grid
								container
								spacing={2}
								alignItems="center"
								//justifyContent="center"
							>
								<Grid item xs={4}>
									<InputLabel id="assessment-select-label">
										Select Assessment
									</InputLabel>
									<Select
										labelId="assessment-select-label"
										id="assessment-select"
										value={assessmentName}
										label="Select Assessment"
										onChange={handleAssessmentSelect}
										sx={{ width: "100%" }}
									>
										{assessments.map((assessment: any) => {
											if (user.ewPassed === true) {
												return (
													<MenuItem
														value={
															assessment.assessmentName
														}
														key={
															assessment.assessmentName
														}
													>
														{
															assessment.assessmentName
														}
													</MenuItem>
												);
											} else if (
												!user.ewPassed &&
												assessment.assessmentName !== "SAP Access for Servisure"
											) {
												return (
													<MenuItem
														value={
															assessment.assessmentName
														}
														key={
															assessment.assessmentName
														}
													>
														{
															assessment.assessmentName
														}
													</MenuItem>
												);
											}
										})}
									</Select>
								</Grid>
								<Grid item xs={4}>
									<Button
										variant="contained"
										color="secondary"
										onClick={handleOpen}
										disabled={startAssessmentDisabled}
									>
										Start Assessment
									</Button>
								</Grid>
							</Grid>
						</FormControl>
						<br />
						<br />
						<Button
							variant="contained"
							color="secondary"
							onClick={handleShowResults}
						>
							Previous Results
						</Button>
						<br />
						<br />
						<ResultsTable
							results={JSON.parse(localStorage.user).results}
							handleClose={handleResultsClose}
							open={resultsOpen}
						/>

						<InfoModal
							modalContent={`Are you sure you would like to take the ${assessmentName} assessment now?`}
							subContent={`Please ensure that you have read through and watched the provided resources prior to taking the assessment. The pass mark is ${assessmentPassMark}%.`}
							yesClick={loadAssessment}
							buttonText={["Yes", "No"]}
							open={open}
							handleClose={handleClose}
						/>
						<InfoModal
							modalContent="Assessment Result:"
							subContent={`${result}% (${
								result < 90 ? "Fail" : "Pass"
							})`}
							isResult={true}
							pass={result < 90 ? false : true}
							yesClick={handleResultModalClose}
							buttonText={["Close"]}
							handleClose={handleResultModalClose}
							open={resultModalOpen}
						/>
						<InfoModal
							isFileViewer={true}
							fileName={fileName}
							handleClose={handleFileViewerClose}
							open={Boolean(fileName)}
							yesClick={handleFileViewerClose}
							buttonText={["Close"]}
						/>
					</Box>
				</Box>
			</ThemeProvider>
		);
	} else {
		return (
			<ThemeProvider theme={theme}>
				<Box sx={{ display: "flex" }}>
					<CssBaseline />
					<AppBar position="fixed">
						<Toolbar>
							<Typography
								variant="h6"
								noWrap
								component="div"
								sx={{ flexGrow: 1 }}
							>
								{pageName}
							</Typography>
						</Toolbar>
					</AppBar>
					<div style={{ margin: "0 auto", paddingTop: "1em" }}>
						<DrawerHeader />
						<Login setUser={setUser} />
					</div>
				</Box>
			</ThemeProvider>
		);
	}
}

export default Home;
