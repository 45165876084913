import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

function Question(props: any) {
    const question = props.question;
    const number = props.number;
    const answers = props.shuffle(props.answers);

    const handleOnChange = (e: any) => {
        let selected = props.selectedAnswers;
        let answerToChange = selected[number - 1] || [];

        answerToChange = e.target.value;
        selected[number - 1] = answerToChange;
        props.setSelectedAnswers(selected);
    }

    return (
        <>
            <FormLabel id={`question-${number}`} key={`form-start-${number}`}>{number}.{question}</FormLabel>
            <RadioGroup aria-labelledby={`question-${number}`} name={`question-${number}`} key={`radio-group-${number}`} onChange={handleOnChange}>
                {answers.map((answer: any, i: number) => {
                    return (
                        <FormControlLabel value={answer[0]} control={<Radio color="secondary"/>} label={answer[0]} key={`answer-${i}`} />
                    );
                })}
            </RadioGroup>
            <br />
        </>
    )
}

export default Question;