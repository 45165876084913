import axios from "axios";
import { API_URL } from '../config';

class DatabaseService {
	addUser = (user: any, token: string | null) => {
		return axios.post(`${API_URL}/user`, user, {
			headers: {
				Authorization: `Bearer: ${token}`,
			},
		});
	};

	findUser = (phoneNumber: string, token: string | null) => {
		return axios.get(`${API_URL}/user/${phoneNumber}`, {
			headers: {
				Authorization: `Bearer: ${token}`,
			},
		});
	};

	getAssessments = (token: string | null) => {
		return axios.get(`${API_URL}/assessments`, {
			headers: {
				Authorization: `Bearer: ${token}`,
			},
		});
	};

	getProviders = (token: string | null) => {
		return axios.get(`${API_URL}/providers`, {
			headers: {
				Authorization: `Bearer: ${token}`,
			},
		});
	};

	getEmailUsers = (assessment: string, token: string | null) => {
		return axios.get(`${API_URL}/emailUsers/${assessment}`, {
			headers: {
				Authorization: `Bearer: ${token}`,
			},
		});
	};

	updateUser = (update: any, token: string | null) => {
		return axios.post(`${API_URL}/user/update`, update, {
			headers: {
				Authorization: `Bearer: ${token}`,
			},
		});
	};
}

export default DatabaseService;
