import axios from "axios";
import { API_URL } from '../config';

class EmailService {
    sendMail = (data: any, token: string | null) => {
        return (axios.post(`${API_URL}/email`, data, {
            headers: {
                Authorization: `Bearer: ${token}`
            }
        }));
    }
}

export default EmailService;