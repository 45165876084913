import { FormEvent, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from "@mui/material";
import DatabaseService from "../Services/DatabaseService";
import AuthService from "../Services/AuthService";

function Login(props: any) {
	const [userValues, setUserValues] = useState({
			phoneNumber: "",
			firstName: "",
			lastName: "",
			serviceProvider: "",
		}),
		[userExists, setUserExists] = useState(true),
		[error, setError] = useState(""),
		[phoneNumberError, setPhoneNumberError] = useState(false),
		[firstNameError, setFirstNameError] = useState(false),
		[lastNameError, setLastNameError] = useState(false),
		[serviceProviderError, setServiceProviderError] = useState(false),
		[serviceProviders, setServiceProviders] = useState([]),
		databaseService = new DatabaseService(),
		authService = new AuthService(),
		handleChange = (prop: keyof any) => (event: any) => {
			setError("");
			setUserValues({ ...userValues, [prop]: event.target.value });
		},
		getProviders = async (token: any) => {
			databaseService
				.getProviders(token)
				.then((res: any) => {
					setServiceProviders(res.data.message);
				})
				.catch((err) => {
					console.error(err);
				});
		},
		checkForUser = async (event: FormEvent) => {
			event.preventDefault();

			let number = userValues.phoneNumber.replace(/\s/g, "");
			const regex = /(\+27|0)[6-8][0-9]{8}/;

			if (regex.test(number) === true) {
				let tokenResponse = await authService.generateAccessToken(
					number
				);
				localStorage.setItem("token", tokenResponse.data.message);

				databaseService
					.findUser(number, localStorage.getItem("token"))
					.then((res) => {
						let user = res.data.message;

						if (user.phoneNumber) {
							localStorage.setItem("user", JSON.stringify(user));
							props.setUser(user);
							setUserExists(true);
						} else {
							getProviders(localStorage.getItem("token"));
							setUserExists(false);
						}
					})
					.catch((error) => {
						setError(
							"There was an error during your login. Please reload the page and try again."
						);
						console.error(error);
					});
			} else {
				setError("Please input a valid South African cell number.");
			}
		},
		createUser = async (event: FormEvent) => {
			event.preventDefault();

			let number = userValues.phoneNumber.replace(/\s/g, "");
			const userInfo = userValues;
			const regex = /(\+27|0)[6-8][0-9]{8}/;

			if (!userValues.phoneNumber) {
				setPhoneNumberError(true);
				setError("Please insert a Phone Number");
			}
			if (!userValues.firstName) {
				setFirstNameError(true);
				setError("Please insert a Name");
			}
			if (!userValues.lastName) {
				setLastNameError(true);
				setError("Please insert a Surname");
			}
			if (regex.test(number) === true) {
				let tokenResponse = await authService.generateAccessToken(
					number
				);
				localStorage.setItem("token", tokenResponse.data.message);

				userInfo.phoneNumber = number;
				databaseService
					.addUser(userInfo, localStorage.getItem("token"))
					.then(() => {
						localStorage.setItem("user", JSON.stringify(userInfo));
						props.setUser(userInfo);
						setUserExists(true);
					})
					.catch((error: any) => {
						console.error(error);
						setError(
							"There was an erorr during your sign up. Please reload the page and try again."
						);
					});
			} else {
				setError("Please input a valid South African cell number.");
			}
		};

	if (userExists) {
		return (
			<Box
				component="form"
				sx={{
					"& .MuiTextField-root": { m: 1, width: "25ch" },
				}}
				autoComplete="off"
				onSubmit={checkForUser}
			>
				<div style={{ textAlign: "center" }}>
					<Typography variant="h5">
						Please input your Phone Number below
					</Typography>
					<TextField
						required
						id="phoneNumber"
						label="Phone Number"
						type="text"
						value={userValues.phoneNumber}
						onChange={handleChange("phoneNumber")}
					/>
					<br />
					<Button
						variant="contained"
						type="submit"
						style={{ backgroundColor: "#ffc221" }}
					>
						Validate
					</Button>
					<Typography variant="h6" color="error">
						{error}
					</Typography>
				</div>
			</Box>
		);
	} else {
		return (
			<Box
				component="form"
				sx={{
					"& .MuiTextField-root": { m: 1, width: "25ch" },
				}}
				autoComplete="off"
				onSubmit={createUser}
			>
				<div style={{ textAlign: "center" }}>
					<Typography variant="h5">
						Please input required details below
					</Typography>
					<TextField
						sx={{ m: 3 }}
						required
						id="phoneNumber"
						label="Phone Number"
						type="number"
						value={userValues.phoneNumber}
						onChange={handleChange("phoneNumber")}
						error={phoneNumberError}
					/>
					<br />
					<TextField
						required
						id="firstName"
						label="Name"
						type="text"
						value={userValues.firstName}
						onChange={handleChange("firstName")}
						error={firstNameError}
					/>
					<br />
					<TextField
						required
						id="lastName"
						label="Surname"
						type="text"
						value={userValues.lastName}
						onChange={handleChange("lastName")}
						error={lastNameError}
					/>
					<br />
					<br />
					<Button
						variant="contained"
						type="submit"
						style={{ backgroundColor: "#ffc221" }}
					>
						Register
					</Button>
					<Typography variant="h6" color="error">
						{error}
					</Typography>
				</div>
			</Box>
		);
	}
}

export default Login;
