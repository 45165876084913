import { Box, Modal, Typography, Button } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";

function ResultsTable(props: any) {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "60vw",
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '3px',
        p: 4,
        textAlign: "center",
        height: '70vh',
        paddingBottom: '8em'
    };

    const columns: GridColumns = [
        { field: 'assessmentName', headerName: 'Assessment Name', flex: 1 },
        {
            field: 'finalMark', headerName: 'Result', width: 100, renderCell: (params: any) => {
                if (params.row.finalMark < 90)
                    return <p style={{ color: '#f44336' }}>Fail ({params.row.finalMark}%)</p>
                else
                    return <p style={{ color: '#4caf50' }}>Pass ({params.row.finalMark}%)</p>
            }
        },
        {
            field: 'assessmentDate', headerName: 'Date', width: 200, renderCell: (params: any) => (
                new Date(params.row.assessmentDate).toLocaleDateString('en-ZA', { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' })
            )
        }
    ];
    let results = props.results;

    if (results && results.length > 0) {
        results.forEach((result: { id: any; }, i: any) => {
            result.id = i;
        })
    }

    return (
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Previous Results
                </Typography>
                <DataGrid
                    initialState={{
                        sorting: {
                            sortModel: [{field: 'assessmentDate', sort: 'desc'}]
                    }
                    }}
                    rows={results}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                />
                <br />
                <div>
                    <Button variant="contained" color="secondary" onClick={props.handleClose}>Close</Button>
                </div>
            </Box>
        </Modal>
    )
}

export default ResultsTable;